import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PageDetails.css";
import detailImage from "./newBack.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import PersonIcon from "@mui/icons-material/Person";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import "bootstrap/dist/css/bootstrap.min.css";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { useLocation } from "react-router-dom";
import TimeElapsed from "./utils/TimeElapsed";
import { Navbar } from "react-bootstrap";
import NavBar from "./components/NavBar";
import DetailSvg from "./details-svg.png";
import ShareIcon from "@mui/icons-material/Share";

const PageDetails = () => {
  const iconStyle = {
    fontSize: "1.2rem",
    marginRight: "10px",
  };

  const location = useLocation();
  const requestDetails = location.state?.donationData;
  const [requestData, setRequestData] = useState([]);

  useEffect(() => {
    const hash = window.location.hash;

    // Extract the required part
    const id = hash.substring(hash.lastIndexOf("/") + 1);
    fetchRequest(id);
  }, []);

  const fetchRequest = (id) => {
    // Fetch donations when the component mounts
    axios
      .get(`https://tabaro3api.onrender.com/donations/${id}`)
      .then((response) => {
        // Update state with fetched donations
        setRequestData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching donations:", error);
      });
  };

  const sharePage = (p1) => {
    if (navigator.share) {
      navigator
        .share({
          title: p1,
          url: window.location.href,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      console.log("Web Share API not supported.");
    }
  };

  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  const redirectToWhatsapp = (phone) => {
    console.log(phone);
    window.location.href = `https://api.whatsapp.com/send?phone=212${phone}&text=السلام عليكم`;
  };

  const redirectToCall = (phone) => {
    console.log(phone);
    window.location.href = `tel:${phone}`;
  };

  function getInitials(text) {
    if (text && typeof text === "string") {
      const words = text.split(" "); // Split the text into words
      const initials = words.map((word) => word.charAt(0)); // Get the first character of each word
      return initials.join("."); // Concatenate the initials with a dot
    }
    return ""; // Return an empty string if text is undefined or not a string
  }

  function getArDate(originalDate) {
    const date = new Date(originalDate);

    // Define options for the date format
    const options = { year: "numeric", month: "long", day: "numeric" };

    // Format the date according to the desired locale
    const formattedDate = date.toLocaleDateString("ar-MA", options); // 'ar-MA' represents Arabic (Morocco) locale
    return formattedDate;
  }

  return (
    <div className="max-h-full h-auto bg-gradient-to-b from-[#EAE1F2] to-[#ffffff] w-screen min-h-screen">
      <NavBar />
      <div className="details-container absolute inset-x-0 top-24 z-50">
        <img src={detailImage} alt="details" className="detail-image" />
        <img src={DetailSvg} alt="details" className="detail-svg" />
        <div className="details-2nd-container">
          <h3 className="request-title">{requestData.title}</h3>
          <div className="location-time-container">
            <h5>
              {<LocationOnIcon style={iconStyle} />} &nbsp;&nbsp;
              {requestData.city}
            </h5>
            <h5 style={{ marginLeft: "20px" }}>
              {<AccessTimeFilledIcon style={iconStyle} />}&nbsp;&nbsp;
              <TimeElapsed datetime={requestData.created_at} />
            </h5>
          </div>
          <hr />
          <div className="second-container">
            <div className="d-flex flex-row align-items-center">
              <div
                style={{ backgroundColor: "#9F0D7F" }}
                className="font-cairo h-12 w-12 flex items-center justify-center text-white rounded-full text-2xl font-bold "
              >
                <span className="text-sm">
                  {getInitials(requestData.requester)}
                </span>
              </div>
              <div className="ms-2 c-details">
                <h6 className="mb-0 font-cairo font-semibold">
                  {requestData.requester}
                </h6>
              </div>
            </div>
            <div className="ver-separator"></div>
            <div className="buttons-container">
              <button
                className="share-button"
                onClick={() => sharePage(requestData.title)}
              >
                <span className="share-text">مشاركة</span> <ShareIcon />
              </button>
              <button
                className="whatsapp-button"
                onClick={() => redirectToWhatsapp(requestData.wtspNumber)}
              >
                <WhatsAppIcon />
              </button>
              <button
                className="call-button"
                onClick={() => redirectToCall(requestData.phoneNumber)}
              >
                اتصل بالمستفيد <CallIcon style={{ marginRight: "10px" }} />
              </button>
            </div>
          </div>
          <hr />
          <div className="third-container">
            <div className="row row-2">
              <div className="col-lg-3 col-md-6 col-12">
                <div>
                  <VolunteerActivismIcon />
                </div>
                <label>العدد المطلوب</label>
                <h5 id="pintQuantity">{requestData.pintQuantity}</h5>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div>
                  <BloodtypeIcon />
                </div>
                <label>فصيلة الدم</label>
                <h5 id="bloodType">{requestData.bloodType}</h5>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div>
                  <DateRangeIcon />
                </div>
                <label>تاريخ التبرع</label>
                <h5 id="donationDate">{getArDate(requestData.donationDate)}</h5>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div>
                  <LocalHospitalIcon />
                </div>
                <label>مركز التبرع</label>
                <h5 id="hospitalName">
                  {truncate(requestData.hospitalName, 20)}
                </h5>
              </div>
            </div>
          </div>
          <hr />
          <div className="fourth-container">
            <h4>الوصف</h4>
            <p className="w-100">{requestData.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageDetails;
