import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "./logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import NavBar from "./components/NavBar";
import axios from "axios";


const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      axios
      .post("https://tabaro3api.onrender.com/send-email", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
       
      })
      .catch((error) => {
        console.log(error);
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="bg-gradient-to-b from-[#EAE1F2] to-[#ffffff] w-screen min-h-screen">
      <NavBar />
      <div class="flex items-center justify-center p-12 absolute inset-x-0 top-24 z-50 font-cairo">
        <div class="mx-auto w-full max-w-[550px]">
          <form onSubmit={handleSubmit}>
            <div class="mb-3">
              <label
                for="name"
                class="mb-3 block text-base font-medium text-[#07074D]"
              >
                الإسم الكامل
              </label>
              <input
                type="text"
                name="name"
                id="name"
                onChange={(e) => handleChange(e)}
                placeholder="اكتب اسمك الكامل هنا"
                class="w-full rounded-md border border-black bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div class="mb-3">
              <label
                for="email"
                class="mb-3 block text-base font-medium text-[#07074D]"
              >
                البريد الإلكتروني
              </label>
              <input
                type="email"
                name="email"
                onChange={(e) => handleChange(e)}
                id="email"
                placeholder="example@domain.com"
                class="w-full rounded-md border border-black bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div class="mb-3">
              <label
                for="subject"
                class="mb-3 block text-base font-medium text-[#07074D]"
              >
                الموضوع
              </label>
              <input
                type="text"
                name="subject"
                id="subject"
                onChange={(e) => handleChange(e)}
                placeholder="اكتب الموضوع هنا"
                class="w-full rounded-md border border-black bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div class="mb-3">
              <label
                for="message"
                class="mb-3 block text-base font-medium text-[#07074D]"
              >
                الرسالة
              </label>
              <textarea
                rows="4"
                name="message"
                id="message"
                onChange={(e) => handleChange(e)}
                placeholder="اكتب رسالتك هنا"
                class="w-full resize-none rounded-md border border-black bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              ></textarea>
            </div>
            <div>
              <button class="w-full hover:shadow-form rounded-md bg-red-500 py-2 px-8 text-base font-semibold text-white outline-none">
                إرسال
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Contact;
