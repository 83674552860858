import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "./logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import NavBar from "./components/NavBar";
import FilterComponent from "./components/FilterComponent";
import Footer from "./components/Footer";

const Donation = () => {
  return (
    <>
    <div className="bg-gradient-to-b from-[#EAE1F2] to-[#ffffff] w-screen min-h-screen flex flex-col">
      <NavBar />
      <FilterComponent />
    </div>
   
    </>
  );
};
export default Donation;
