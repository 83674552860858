import React from "react";

const BuyMeACoffeeButton = () => {
  const buttonStyle = {
    textDecoration: "none",
    display: "inline-flex",
    alignItems: "center",
    color: "#ffffff",
    backgroundColor: "#FF3F3F",
    borderRadius: "5px",
    border: "1px solid transparent",
    padding: "0.5rem",
    fontSize: "1rem",
    letterSpacing: "0.6px",
    boxShadow: "0px 1px 2px rgba(190, 190, 190, 0.5)",
    fontFamily: "cursive",
  };

  const buttonTextStyle = {
    marginLeft: "15px",
    fontSize: "0.8rem",
    verticalAlign: "middle",
    fontFamily: "cairo",
    fontWeight: "bold",
  };

  const imgStyle = {
    height: "3vh",
    boxShadow: "none",
    border: "none",
    verticalAlign: "middle",
    filter: 'brightness(0) invert(1) grayscale(100%) contrast(1000%)',
  };

  return (
    <a
      style={buttonStyle}
      className="buyButton"
      target="_blank"
      href="https://www.buymeacoffee.com/tabaro3.online"
    >
      <span style={buttonTextStyle}>ساهم معنا في مصاريف الموقع</span>
      <img
        style={imgStyle}
        className="coffeeImage"
        src="https://cdn-icons-png.flaticon.com/512/2194/2194762.png"
        alt="Buy me a coffee"
      />
    </a>
  );
};

export default BuyMeACoffeeButton;
