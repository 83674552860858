import React from "react";
import logo from "../logo.svg";

const Footer = () => {
  return (
    <footer className="bg-white relative bottom-0 inset-x-0  mt-3 ">
      <div className="w-full max-w-screen-xl mx-auto p-4 ">
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400 font-bold font-cairo">
          تم تطوير الموقع بواسطة{" "}
          <a
            href="https://www.elhattabidev.online/"
            className="no-underline text-red-500"
            target="_blank"
          >
            حمزة الحطابي
          </a>{" "}
          &copy; {new Date().getFullYear()}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
