import logo from "./logo.svg";
import "./App.css";
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Example from "./Example";
import About from "./About";
import Donation from "./Donation";
import Contact from "./Contact";
import PageDetails from "./PageDetails";
import BloodDonationSurvey from "./BloodDonationSurvey";

function App() {
  return (
    <Router>
      <>
        <Routes>
          <Route path="/" element={<Example />} />
          <Route path="/about" element={<About/>} />
          <Route path="/donations" element={<Donation/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/survey" element={<BloodDonationSurvey/>} />
          <Route path="/details/:id" element={<PageDetails/>} />
        </Routes>
      </>
    </Router>
  );
}

export default App;
