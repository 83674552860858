import React from 'react';

const TimeElapsed = ({ datetime }) => {
    const getTimeElapsed = (datetime) => {
        const currentTime = new Date();
        const pastTime = new Date(datetime);
        const elapsedMilliseconds = currentTime - pastTime;
        const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

        if (elapsedSeconds < 60) {
            return `منذ ${elapsedSeconds} ثانية `;
        }

        const elapsedMinutes = Math.floor(elapsedSeconds / 60);

        if (elapsedMinutes < 60) {
            return `منذ ${elapsedMinutes} دقيقة`;
        }

        const elapsedHours = Math.floor(elapsedMinutes / 60);

        if (elapsedHours < 24) {
            return `منذ ${elapsedHours} ساعة`;
        }

        const elapsedDays = Math.floor(elapsedHours / 24);
        return `منذ ${elapsedDays} يوم`;
    };

    const timeElapsed = getTimeElapsed(datetime);

    return <span>{timeElapsed}</span>;
};

export default TimeElapsed;
