import React, { Fragment, useState, useEffect } from "react";
import { Listbox, Transition, Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import "bootstrap/dist/css/bootstrap.min.css";
import TimeElapsed from "../utils/TimeElapsed";
import axios from "axios";
import "./style.css";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog2 from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Request from "./RequestBlood";
import Typography from "@mui/material/Typography";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import NearMeIcon from "@mui/icons-material/NearMe";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Checkbox from "@mui/material/Checkbox";
import ErrorIcon from "@mui/icons-material/Error";
import FormControlLabel from "@mui/material/FormControlLabel";
import { NavLink, useNavigate, useLocation, json } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "./Footer";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Transition2 = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const cities = [
  { name: "الكل" },
  { name: "الدار البيضاء" },
  { name: "الرباط" },
  { name: "فاس" },
  { name: "سطات" },
  { name: "طنجة" },
  { name: "مراكش" },
  { name: "مكناس" },
  { name: "أكادير" },
  { name: "وجدة" },
  { name: "العيون" },
  { name: "تطوان" },
  { name: "الحسيمة" },
  { name: "الجديدة" },
  { name: "سلا" },
  { name: "القنيطرة" },
  { name: "المحمدية" },
  { name: "بني ملال" },
  { name: "خريبكة" },
  { name: "طانطان" },
  { name: "الرشيدية" },
  { name: "تازة" },
  { name: "إفران" },
  { name: "الصويرة" },
  { name: "الداخلة" },
  { name: "تيفلت" },
  { name: "سيدي قاسم" },
  { name: "برشيد" },
  { name: "القصر الكبير" },
  { name: "الفقيه بن صالح" },
  { name: "ميدلت" },
  { name: "سيدي سليمان" },
  { name: "زاكورة" },
  { name: "ورزازات" },
  { name: "تارودانت" },
  { name: "تاونات" },
  { name: "العرائش" },
  { name: "قلعة السراغنة" },
  { name: "سيدي إفني" },
  { name: "تاوجطات" },
  { name: "إنزكان" },
  { name: "سيدي بنور" },
  { name: "تنغير" },
  { name: "اليوسفية" },
  { name: "سيدي يوسف بن علي" },
  { name: "مريرت" },
  { name: "تازيازت" },
  { name: "تاهلة" },
  { name: "بركان" },
  { name: "الجرف الأصفر" },
  { name: "عين الشق" },
  { name: "سيدي إفني" },
  { name: "بوجدور" },
  { name: "كلميم" },
  { name: "العرائش" },
  // Add more cities as needed
];

const bloodType = [
  { id: "all", name: "الكل" },
  { id: "A+", name: "فصيلة الدم A+" },
  { id: "A-", name: "فصيلة الدم A-" },
  { id: "B+", name: "فصيلة الدم B+" },
  { id: "B-", name: "فصيلة الدم B-" },
  { id: "AB+", name: "فصيلة الدم AB+" },
  { id: "AB-", name: "فصيلة الدم AB-" },
  { id: "O+", name: "فصيلة الدم O+" },
  { id: "O-", name: "فصيلة الدم O-" },
];

const sortByOptions = [
  { id: 2, name: "ترتيب حسب التاريخ التنازلي" },
  { id: 1, name: "ترتيب حسب التاريخ التصاعدي" },
];

const ITEMS_PER_PAGE = 9; // Number of items to display per page

const FilterComponent = () => {
  const [currentDonations, setCurrentDonations] = useState([]);
  const [reservedDonations, setReservedDonations] = useState([]);
  const [checked, setChecked] = useState(false);

  const [selected, setSelected] = useState(cities[0]);
  const [query, setQuery] = useState("");

  const [selected1, setSelected1] = useState(bloodType[0]);
  const [query1, setQuery1] = useState("");

  const [selectedSortBy, setSelectedSortBy] = useState(sortByOptions[0]);
  const [querySortBy, setQuerySortBy] = useState(""); // Define querySortBy
  const [filteredSortBy, setFilteredSortBy] = useState(sortByOptions); // Define filteredSortBy

  const [currentPage, setCurrentPage] = useState(1); // Current page number

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Calculate start and end indexes for pagination
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  // Slice the donations based on the current page
  const paginatedDonations = currentDonations.slice(startIndex, endIndex);

  // Handle pagination click
  const handlePaginationClick = (page) => {
    setCurrentPage(page);
  };

  // Filtered data based on query for Sort By options
  const filterSortByOptions = (sortByQuery) => {
    if (sortByQuery === "") {
      setFilteredSortBy(sortByOptions);
    } else {
      const filteredOptions = sortByOptions.filter((option) =>
        option.name.toLowerCase().includes(sortByQuery.toLowerCase())
      );
      setFilteredSortBy(filteredOptions);
    }
  };

  const filteredCity =
    query === ""
      ? cities
      : cities.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const filteredBloodType =
    query1 === ""
      ? bloodType
      : bloodType.filter((person) =>
          person.id
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query1.toLowerCase().replace(/\s+/g, ""))
        );

  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  function getInitials(text) {
    const words = text.split(" "); // Split the text into words
    const initials = words.map((word) => word.charAt(0)); // Get the first character of each word
    return initials.join("."); // Concatenate the initials with a dot
  }

  useEffect(() => {
    fetchdonations();
  }, []);

  const navigate = useNavigate();
  // const [requestDetails, setRequestDetails] = useState({});

  const handleSeeDetails = (id) => {
    let donationData = currentDonations.filter(
      (donation) => donation._id === id
    );
    // donationData=JSON.stringify(donationData[0], null, 2);
    console.log(donationData);
    navigate("/details/" + id, { state: { donationData } });
  };

  const fetchdonations = () => {
    setLoading(true); // Set loading state to true when starting to fetch donations
    // Fetch donations when the component mounts
    axios
      .get("https://tabaro3api.onrender.com/donations")
      .then((response) => {
        // Update state with fetched donations
        setCurrentDonations(response.data);
        setReservedDonations(response.data);
      })
      .catch((error) => {
        console.error("Error fetching donations:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading state to false after fetching is done
      });
  };

  // setInterval(fetchdonations, 60000);

  const filterDonations = () => {
    let filteredDonations = [...currentDonations];

    if (selected.name !== "الكل" && selected1.id === "all") {
      // Filter by city only
      filteredDonations = reservedDonations.filter(
        (donation) => donation.city === selected.name
      );
    } else if (selected1.id !== "all" && selected.name === "الكل") {
      // Filter by blood type only
      filteredDonations = reservedDonations.filter(
        (donation) => donation.bloodType === selected1.id
      );
    } else if (selected.name !== "الكل" && selected1.id !== "all") {
      // Filter by both city and blood type
      filteredDonations = reservedDonations.filter(
        (donation) =>
          donation.city === selected.name && donation.bloodType === selected1.id
      );
    } else if (selected.name === "الكل" && selected1.id === "all") {
      fetchdonations();
    }

    if (checked) {
      filteredDonations = filteredDonations.filter(
        (donation) => donation.urgent == 1
      );
    }

    console.log("checked: " + checked);
    // Sort by selected option
    if (selectedSortBy.id === 1) {
      // Sort by ascending date
      filteredDonations.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateA - dateB;
      });
    } else if (selectedSortBy.id === 2) {
      // Sort by descending date
      filteredDonations.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });
    }

    setCurrentDonations(filteredDonations);
  };

  const Card = (props) => {
    return (
      <div className="col-md-6 col-lg-4 mb-2">
        <div className="card donate-card p-3 mb-2 shadow-md">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <div
                style={{ backgroundColor: "#9F0D7F" }}
                className="font-cairo h-12 w-12 flex items-center justify-center text-white rounded-full text-2xl font-bold "
              >
                <span className="text-sm">
                  {getInitials(props.request.requester)}
                </span>
              </div>
              <div className="ms-2 c-details">
                <h6 className="mb-0 font-cairo font-semibold">
                  {props.request.requester}
                </h6>{" "}
                <span className="date-span font-cairo text-sm">
                  <TimeElapsed datetime={props.request.created_at} />
                </span>
              </div>
            </div>
            {props.request.urgent === 1 && (
              <div className="urgent font-cairo font-semibold h-8 bg-red-500 text-white px-2 flex flex-col justify-center  rounded-full">
                <span>مستعجل</span>
              </div>
            )}
          </div>
          <div className="mt-3">
            <h3 className="heading font-cairo font-bold">
              {truncate(props.request.title, 25)}
            </h3>
            <div className="mt-3">
              <div className="text-white">
                <div
                  className="progress-bar  rounded-lg"
                  style={{
                    width: `100%`,
                    backgroundColor: "#9F0D7F",
                  }}
                >
                  <span className="text1 font-cairo">
                    {props.request.pintQuantity} متبرعين
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 blood-group font-cairo flex flex-row justify-around items-center">
              <span className="text-red-700">
                <BloodtypeIcon /> فصيلة الدم: {props.request.bloodType}
              </span>
              <span className="text-green-700">
                <NearMeIcon /> المدينة: {props.request.city}
              </span>
            </div>
          </div>
          <div className="mt-3">
            <button
              type="button"
              className="btn w-full custom-btn-style"
              onClick={() => handleSeeDetails(props.request._id)}
            >
              <span className="font-cairo font-bold text-white">التفاصيل</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-6 w-screen absolute inset-x-0 top-24 z-50">
      <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-6">
        <Combobox
          value={selected}
          onChange={setSelected}
          className="w-full mr-6 ml-6 md:w-1/2 "
        >
          <div className="relative">
            <div className="rounded-sm relative w-full cursor-default overflow-hidden  bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-red-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 font-cairo"
                displayValue={(person) => person.name}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 left-0 flex items-center pl-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
              className="z-50"
            >
              <Combobox.Options className="font-cairo absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {filteredCity.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredCity.map((person, index) => (
                    <Combobox.Option
                      key={index}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-red-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person.name}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-red-600"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
        <Combobox
          value={selected1}
          onChange={setSelected1}
          className="w-full md:w-1/2"
        >
          <div className="relative ">
            <div className="rounded-sm relative w-full cursor-default overflow-hidden  bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-red-300 sm:text-sm">
              <Combobox.Input
                className="font-cairo w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                displayValue={(person) => person.name}
                onChange={(event) => setQuery1(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 left-0 flex items-center pl-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery1("")}
              className="z-50"
            >
              <Combobox.Options className="font-cairo absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {filteredBloodType.length === 0 && query1 !== "" ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredBloodType.map((person) => (
                    <Combobox.Option
                      key={person.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-red-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={person}
                    >
                      {({ selected1, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected1 ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person.name}
                          </span>
                          {selected1 ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-red-600"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
        <Combobox
          value={selectedSortBy}
          onChange={setSelectedSortBy}
          className="w-full md:w-1/2"
        >
          <div className="relative">
            <div className="rounded-sm relative w-full cursor-default overflow-hidden bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-red-300 sm:text-sm">
              <Combobox.Input
                className="font-cairo w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                displayValue={(option) => option.name}
                onChange={(event) => setQuerySortBy(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 left-0 flex items-center pl-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuerySortBy("")}
              className="z-50"
            >
              <Combobox.Options className="font-cairo absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {/* Option list for sorting */}
                {filteredSortBy.length === 0 && querySortBy !== "" ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredSortBy.map((option) => (
                    <Combobox.Option
                      key={option.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-red-600 text-white" : "text-gray-900"
                        }`
                      }
                      value={option}
                    >
                      {({ selectedSortBy, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selectedSortBy ? "font-medium" : "font-normal"
                            }`}
                          >
                            {option.name}
                          </span>
                          {selectedSortBy ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-red-600"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
        <FormControlLabel
          style={{
            background: "white",
            height: "37px",
            paddingLeft: "15px",
            borderRadius: "3px",
          }}
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              color="error"
              icon={<ErrorOutlineOutlinedIcon />}
              checkedIcon={<ErrorIcon />}
            />
          }
          label={
            <Typography
              variant="body1"
              style={{
                fontFamily: "cairo",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              مستعجل
            </Typography>
          }
        />
        <button
          onClick={() => filterDonations()}
          className="w-full h-9 font-extrabold shadow-md rounded-md font-cairo backface-visibility-hidden mt-8 flex transform items-center bg-red-700 text-white px-3 py-1 text-sm font-medium transition hover:scale-105 hover:bg-black/30 hover:text-red focus:outline-none active:bg-black/40"
        >
          <span className="ml-3">فلتر</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
            />
          </svg>
        </button>
      </div>
      <div className="cards-container h-full pt-4 w-full">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading} // Show backdrop based on loading state
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="row mb-3">
          {/* Display cards only when data is available */}
          {paginatedDonations.length === 0 && !loading? (
            <Alert
              severity="error"
              style={{ width: "70%", margin: "50px auto" }}
            >
              <strong className="font-cairo font-bold pr-9">
                نعتذر لا يوجد طلب الآن
              </strong>
            </Alert>
          ) : (
            <div className="cards-container h-full pt-4 w-full">
              {/* Display fetched data */}
              <div className="row mb-3">
                {paginatedDonations.map((request, i) => (
                  <Card key={i} request={request} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Pagination */}
      <div className="flex justify-center mt-2">
        {Array.from(
          { length: Math.ceil(currentDonations.length / ITEMS_PER_PAGE) },
          (_, i) => i + 1
        ).map((page) => (
          <button
            key={page}
            className={`mr-2 font-cairo py-1 px-3 rounded-md ${
              currentPage === page
                ? "bg-red-600 text-white"
                : "bg-white text-red-600"
            }`}
            onClick={() => handlePaginationClick(page)}
          >
            {page}
          </button>
        ))}
      </div>
      <Footer />
      <div style={{ position: "fixed", bottom: "20px", left: "20px" }}>
        <Fab
          onClick={() => handleClickOpen()}
          size="medium"
          color="error"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      </div>
      <Dialog2
        xs={{ fontFamily: "cairo" }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition2}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#C51605" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h7"
              component="div"
              style={{ fontFamily: "cairo", fontWeight: "700" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;إضافة طلب
            </Typography>
            {/* <Button
              autoFocus
              color="inherit"
              style={{ fontFamily: "cairo", fontWeight: "700" }}
            >
              نشر
            </Button> */}
          </Toolbar>
        </AppBar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Request />
        </div>
      </Dialog2>
    </div>
  );
};

export default FilterComponent;
