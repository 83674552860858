import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import "./RequestBlood.css";
import axios from "axios";
import { CircularProgress, Alert, Backdrop, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const cities = [
  { name: "الدار البيضاء" },
  { name: "الرباط" },
  { name: "فاس" },
  { name: "سطات" },
  { name: "طنجة" },
  { name: "مراكش" },
  { name: "مكناس" },
  { name: "أكادير" },
  { name: "وجدة" },
  { name: "العيون" },
  { name: "تطوان" },
  { name: "الحسيمة" },
  { name: "الجديدة" },
  { name: "سلا" },
  { name: "القنيطرة" },
  { name: "المحمدية" },
  { name: "بني ملال" },
  { name: "خريبكة" },
  { name: "طانطان" },
  { name: "الرشيدية" },
  { name: "تازة" },
  { name: "إفران" },
  { name: "الصويرة" },
  { name: "الداخلة" },
  { name: "تيفلت" },
  { name: "سيدي قاسم" },
  { name: "برشيد" },
  { name: "القصر الكبير" },
  { name: "الفقيه بن صالح" },
  { name: "ميدلت" },
  { name: "سيدي سليمان" },
  { name: "زاكورة" },
  { name: "ورزازات" },
  { name: "تارودانت" },
  { name: "تاونات" },
  { name: "العرائش" },
  { name: "قلعة السراغنة" },
  { name: "سيدي إفني" },
  { name: "تاوجطات" },
  { name: "إنزكان" },
  { name: "سيدي بنور" },
  { name: "تنغير" },
  { name: "اليوسفية" },
  { name: "سيدي يوسف بن علي" },
  { name: "مريرت" },
  { name: "تازيازت" },
  { name: "تاهلة" },
  { name: "بركان" },
  { name: "الجرف الأصفر" },
  { name: "عين الشق" },
  { name: "سيدي إفني" },
  { name: "بوجدور" },
  { name: "كلميم" },
  { name: "العرائش" },
  // Add more cities as needed
];

const RequestBlood = () => {
  const [validated, setValidated] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [bloodType, setBloodType] = useState("");
  const [pintQuantity, setPintQuantity] = useState(0);
  const [city, setCity] = useState("الدار البيضاء");
  const [donationDate, setDonationDate] = useState("");
  const [urgent, setUrgent] = useState(false);
  const [hospitalName, setHospitalName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [wtspNum, setWtspNum] = useState("");
  const [beneficiary, setBeneficiary] = useState("");

  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [isValidWtspNum, setIsValidWtspNum] = useState(false);

  const handleWtspChange = (e) => {
    const inputWtspNum = e.target.value;
    const wtspRegex = /^(06|07)\d{8}$/; // Regex for country code with at least 6 digits
    const isValid = wtspRegex.test(inputWtspNum);
    setIsValidWtspNum(isValid);
    setWtspNum(inputWtspNum);
  };

  const handlePhoneChange = (e) => {
    const inputPhoneNumber = e.target.value;
    const phoneRegex = /^(06|07)\d{8}$/; // Regex for 06 or 07 followed by 8 digits
    const isValid = phoneRegex.test(inputPhoneNumber);
    setIsValidPhoneNumber(isValid);
    setPhoneNumber(inputPhoneNumber);
  };

  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setShowSnackbar(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      postRequest();
    }
    setValidated(true);
  };

  const postRequest = () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("created_at", new Date());
    formData.append("description", description);
    formData.append("bloodType", bloodType);
    formData.append("pintQuantity", pintQuantity);
    formData.append("city", city);
    formData.append("donationDate", donationDate);
    formData.append("urgent", urgent ? 1 : 0);
    formData.append("hospitalName", hospitalName);
    formData.append("phoneNumber", phoneNumber);
    formData.append("wtspNumber", wtspNum);
    formData.append("requester", beneficiary);

    setLoading(true);

    axios
      .post("https://tabaro3api.onrender.com/donations", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        // Handle successful request creation...
        handleSnackbar("تم إنشاء الطلب بنجاح", "success");
        setLoading(false);
        // Wait for 3 seconds and then perform additional actions
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        // Handle error...
        handleSnackbar("Failed to create request", "error");
        setLoading(false);
      });
  };

  const Loader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress color="warning" thickness={5.6} />{" "}
        {/* Or any other loading indicator component */}
      </div>
    );
  };

  return (
    <div className="post-request-container">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3 w-screen px-5"> <span className="text-red-500 italic">ملاحظة: هذا الطلب سيحدف تلقائيا بعد يوم التبرع بيوم</span> </Row>
        <Row className="mb-3 w-screen px-5">
          <Form.Group as={Col} md="12" controlId="request-title-input">
            <Form.Label>عنوان الطلب:</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="أدخل عنوان طلبك"
              onChange={(e) => setTitle(e.target.value)}
            />
            <Form.Control.Feedback>يبدو جيدًا!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 w-screen px-5">
          <Form.Group as={Col} md="6" controlId="request-blood-group-input">
            <Form.Label>فصيلة الدم:</Form.Label>
            <Form.Control
              as="select"
              required
              onChange={(e) => setBloodType(e.target.value)}
            >
              <option value="" disabled selected>
                اختر فصيلة الدم
              </option>
              <option>A+</option>
              <option>A-</option>
              <option>B-</option>
              <option>B+</option>
              <option>O-</option>
              <option>O+</option>
              <option>AB-</option>
              <option>AB+</option>
            </Form.Control>
            <Form.Control.Feedback>يبدو جيدًا!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="donation-date-input">
            <Form.Label>تاريخ التبرع:</Form.Label>
            <Form.Control
              type="date"
              required
              onChange={(e) => setDonationDate(e.target.value)}
            />
            <Form.Control.Feedback>يبدو جيدًا!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 w-screen px-5">
          <Form.Group as={Col} md="6" controlId="request-phone-input">
            <Form.Label>الهاتف:</Form.Label>
            <Form.Control
              required
              type="tel"
              placeholder="أدخل رقم هاتفك"
              value={phoneNumber}
              onChange={handlePhoneChange}
              isInvalid={phoneNumber.length > 0 && !isValidPhoneNumber}
            />
            <Form.Control.Feedback type="invalid">
              يجب أن يبدأ الرقم بـ 06 أو 07 ويتكون من 10 أرقام
            </Form.Control.Feedback>
            <Form.Control.Feedback>يبدو جيدًا!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="request-wtsp-input">
            <Form.Label>الواتساب:</Form.Label>
            <Form.Control
              required
              type="tel"
              placeholder="أدخل رقم الواتساب"
              value={wtspNum}
              onChange={handleWtspChange}
              isInvalid={wtspNum.length > 0 && !isValidWtspNum}
            />
            <Form.Control.Feedback type="invalid">
              يجب أن يكون رقم واتساب صالح
            </Form.Control.Feedback>
            <Form.Control.Feedback>يبدو جيدًا!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 w-screen px-5">
          <Form.Group as={Col} md="6" controlId="request-beneficiary-input">
            <Form.Label>اسم المستفيد:</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="أدخل اسم المستفيد"
              onChange={(e) => setBeneficiary(e.target.value)}
            />
            <Form.Control.Feedback>يبدو جيدًا!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="donation-disctrict-input">
            <Form.Label>المنطقة:</Form.Label>
            <Form.Control
              as="select"
              required
              onChange={(e) => setCity(e.target.value)}
            >
              <option value="" disabled selected>
                اختر المدينة
              </option>
              {cities.map((c, i) => (
                <option key={i}>{c.name}</option>
              ))}
            </Form.Control>
            <Form.Control.Feedback>يبدو جيدًا!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 w-screen px-5">
          <Form.Group as={Col} md="6" controlId="donation-center-input">
            <Form.Label>اسم المستشفى أو المركز:</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="أدخل اسم مركز التبرع"
              onChange={(e) => setHospitalName(e.target.value)}
            />
            <Form.Control.Feedback>يبدو جيدًا!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="pint-quantity-input">
            <Form.Label>عدد المتبرعين:</Form.Label>
            <Form.Control
              as="input"
              type="number"
              placeholder="اختر عدد المتبرعين"
              value={pintQuantity ? pintQuantity : 1}
              onChange={(e) => setPintQuantity(e.target.value)}
              min="1" // Optional: Set minimum value allowed
              required
            />
            <Form.Control.Feedback>يبدو جيدًا!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        {/* <Row className="mb-3 w-screen px-5">
          <Form.Group as={Col} md="12" controlId="delete-request-date-input">
            <Form.Label>
              تاريخ حذف الطلب{" "}
              <span style={{ fontSize: "smaller" }}>
                (افتراضيًا 1 يوم من تاريخ التبرع)
              </span>
              :
            </Form.Label>
            <Form.Control
              type="date"
              required
              placeholder="أدخل تاريخ التبرع"
              onChange={(e) => setDeleteRequest(e.target.value)}
            />
            <Form.Control.Feedback>يبدو جيدًا!</Form.Control.Feedback>
          </Form.Group>
        </Row> */}
        <Row className="mb-4 w-screen px-5">
          <Form.Group as={Col} md="12" controlId="description-input">
            <Form.Label>الوصف:</Form.Label>
            <Form.Control
              required
              as="textarea"
              rows={4}
              placeholder="أضف تفاصيل إضافية هنا..."
              onChange={(e) => setDescription(e.target.value)}
            />
            <Form.Control.Feedback>يبدو جيدًا!</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 w-screen px-5">
          <Form.Group as={Col} md="4" controlId="urgent-checkbox">
            <Form.Check
              label="طلب عاجل"
              className="red-checkbox"
              onChange={(e) => setUrgent(e.target.checked)}
            />
          </Form.Group>
        </Row>
        <Row className="mb-4 w-screen px-5">
          <Form.Group as={Col} md="6" controlId="privacy-checkbox">
            <Form.Check
              label="لقد قرأت وأوافق على الشروط وسياسة الخصوصية"
              required
              className="red-checkbox"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 w-screen px-5">
          <Button type="submit" variant="danger">
            نشر
          </Button>
        </Row>
      </Form>

      <Backdrop open={loading} style={{ zIndex: 999 }}>
        <Loader />
      </Backdrop>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbarSeverity} elevation={6} variant="filled" className="font-cairo">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RequestBlood;
