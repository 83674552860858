import React, { useState, Fragment } from "react";
import NavBar from "./components/NavBar";
import { Dialog, Transition } from "@headlessui/react";

const BloodDonationSurvey = () => {
  const [age, setAge] = useState("");
  const [weight, setWeight] = useState("");
  const [healthIssues, setHealthIssues] = useState("");
  const [medications, setMedications] = useState("");
  const [recentSurgeries, setRecentSurgeries] = useState("");
  const [travelHistory, setTravelHistory] = useState("");
  const [recentTattoos, setRecentTattoos] = useState("");
  const [intravenousDrugs, setIntravenousDrugs] = useState("");
  // Add more state variables for other questions as needed
  let [isOpen, setIsOpen] = useState(false);
  let [msg, setMsg] = useState("");

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(msg) {
    setMsg(msg);
    setIsOpen(true);
  }
  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  };

  // Add handlers for other questions
  const handleHealthIssuesChange = (event) => {
    setHealthIssues(event.target.value);
  };

  const handleMedicationsChange = (event) => {
    setMedications(event.target.value);
  };

  const handleRecentSurgeriesChange = (event) => {
    setRecentSurgeries(event.target.value);
  };

  const handleTravelHistoryChange = (event) => {
    setTravelHistory(event.target.value);
  };

  const handleRecentTattoosChange = (event) => {
    setRecentTattoos(event.target.value);
  };

  const handleIntravenousDrugsChange = (event) => {
    setIntravenousDrugs(event.target.value);
  };
  // Add more handlers for other questions as needed

  const handleSubmit = (event) => {
    event.preventDefault();

    // Analyze the answers
    const isEligible = checkEligibility(
      age,
      weight,
      healthIssues,
      medications,
      recentSurgeries,
      travelHistory,
      recentTattoos,
      intravenousDrugs
      // Add more parameters for other questions
    );

    // Display the result
    if (isEligible) {
      openModal("أنت مؤهل للتبرع بالدم.");
    } else {
      openModal("أنت لست مؤهلاً للتبرع بالدم.");
    }
  };

  // Function to check eligibility based on criteria
  const checkEligibility = (
    age,
    weight,
    healthIssues,
    medications,
    recentSurgeries,
    travelHistory,
    recentTattoos,
    intravenousDrugs
    // Add more parameters for other questions
  ) => {
    // Perform logic to determine eligibility based on criteria
    const isAgeEligible = parseInt(age) >= 18 && parseInt(age) <= 65;
    const isWeightEligible = parseInt(weight) >= 50; // Adjust weight limit as per guidelines
    const hasNoHealthIssues = healthIssues.toLowerCase().trim() === "لا";
    const noMedications = medications.toLowerCase().trim() === "لا";
    const noRecentSurgeries = recentSurgeries.toLowerCase().trim() === "لا";
    const noTravelToRiskAreas = travelHistory.toLowerCase().trim() === "لا";
    const noRecentTattoos = recentTattoos.toLowerCase().trim() === "لا";
    const noIntravenousDrugs = intravenousDrugs.toLowerCase().trim() === "لا";
    // Add more criteria based on other questions

    return (
      isAgeEligible &&
      isWeightEligible &&
      hasNoHealthIssues &&
      noMedications &&
      noRecentSurgeries &&
      noTravelToRiskAreas &&
      noRecentTattoos &&
      noIntravenousDrugs
      // Add more conditions for other questions
    );
  };

  return (
    <div className="bg-gradient-to-b from-[#EAE1F2] to-[#ffffff] w-screen min-h-screen">
      <NavBar />
      <div className="flex items-center justify-center p-12 absolute inset-x-0 top-24 font-cairo">
        <div className="mx-auto w-full max-w-[550px]">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label
                htmlFor="age"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                العمر
              </label>
              <input
                type="number"
                name="age"
                id="age"
                placeholder="ادخل عمرك هنا"
                value={age}
                onChange={handleAgeChange}
                className="w-full rounded-md border border-black bg-white py-1 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="weight"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                الوزن (بالكيلوغرام)
              </label>
              <input
                type="number"
                name="weight"
                id="weight"
                placeholder="ادخل وزنك هنا"
                value={weight}
                onChange={handleWeightChange}
                className="w-full rounded-md border border-black bg-white py-1 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              />
            </div>

            <div className="mb-3">
              <label
                htmlFor="healthIssues"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                هل لديك أي مشاكل صحية حالية؟ (نعم/لا)
              </label>
              <select
                type="text"
                name="healthIssues"
                id="healthIssues"
                placeholder="اكتب نعم أو لا هنا"
                value={healthIssues}
                onChange={handleHealthIssuesChange}
                className="w-full rounded-md border border-black bg-white py-1 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              >
                <option value="">اختر</option>
                <option value="نعم">نعم</option>
                <option value="لا">لا</option>
              </select>
            </div>

            <div className="mb-3">
              <label
                htmlFor="medications"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                هل تتناول أي أدوية؟ (نعم/لا)
              </label>
              <select
                type="text"
                name="medications"
                id="medications"
                placeholder="اكتب نعم أو لا هنا"
                value={medications}
                onChange={handleMedicationsChange}
                className="w-full rounded-md border border-black bg-white py-1 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              >
                <option value="">اختر</option>
                <option value="نعم">نعم</option>
                <option value="لا">لا</option>
              </select>
            </div>

            <div className="mb-3">
              <label
                htmlFor="recentSurgeries"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                هل خضعت لعمليات جراحية مؤخرًا؟ (نعم/لا)
              </label>
              <select
                type="text"
                name="recentSurgeries"
                id="recentSurgeries"
                placeholder="اكتب نعم أو لا هنا"
                value={recentSurgeries}
                onChange={handleRecentSurgeriesChange}
                className="w-full rounded-md border border-black bg-white py-1 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              >
                <option value="">اختر</option>
                <option value="نعم">نعم</option>
                <option value="لا">لا</option>
              </select>
            </div>

            <div className="mb-3">
              <label
                htmlFor="travelHistory"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                هل سافرت لمناطق ذات مخاطر مؤخرًا؟ (نعم/لا)
              </label>
              <select
                type="text"
                name="travelHistory"
                id="travelHistory"
                placeholder="اكتب نعم أو لا هنا"
                value={travelHistory}
                onChange={handleTravelHistoryChange}
                className="w-full rounded-md border border-black bg-white py-1 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              >
                <option value="">اختر</option>
                <option value="نعم">نعم</option>
                <option value="لا">لا</option>
              </select>
            </div>

            <div className="mb-3">
              <label
                htmlFor="recentTattoos"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                هل حصلت على وشم أو ثقب حديثًا؟ (نعم/لا)
              </label>
              <select
                type="text"
                name="recentTattoos"
                id="recentTattoos"
                placeholder="اكتب نعم أو لا هنا"
                value={recentTattoos}
                onChange={handleRecentTattoosChange}
                className="w-full rounded-md border border-black bg-white py-1 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              >
                <option value="">اختر</option>
                <option value="نعم">نعم</option>
                <option value="لا">لا</option>
              </select>
            </div>

            <div className="mb-3">
              <label
                htmlFor="intravenousDrugs"
                className="mb-3 block text-base font-medium text-[#07074D]"
              >
                هل تستخدم المخدرات عبر الوريد؟ (نعم/لا)
              </label>
              <select
                type="text"
                name="intravenousDrugs"
                id="intravenousDrugs"
                placeholder="اكتب نعم أو لا هنا"
                value={intravenousDrugs}
                onChange={handleIntravenousDrugsChange}
                className="w-full rounded-md border border-black bg-white py-1 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              >
                <option value="">اختر</option>
                <option value="نعم">نعم</option>
                <option value="لا">لا</option>
              </select>
            </div>

            <button className="w-full hover:shadow-form rounded-md bg-red-500 py-2 px-8 text-base font-semibold text-white outline-none">
              تحقق
            </button>
          </form>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          {/* Background overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                  {/* Centered content */}
                  <div className="mt-2">
                    <p className="text-sm text-black font-cairo inline-block">
                      {msg}
                    </p>
                  </div>
                  <div className="mt-2">
                    <button
                      type="button"
                      className="font-cairo inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      حسنا, شكرا
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default BloodDonationSurvey;
