import React, { Fragment, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "./logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import NavBar from "./components/NavBar";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog2 from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Request from "./components/RequestBlood";
import Typography from "@mui/material/Typography";
import BuyMeACoffeeButton from "./components/BuyMeACoffeeButton";

const Transition2 = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Example = () => {
  const navigate = useNavigate();
  const handleMove = () => {
    navigate("/donations");
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="bg-white h-screen">
      <NavBar />

      <div className="relative h-full px-6 pt-14 lg:px-8 flex flex-col justify-center items-center">
        <div
          className="absolute inset-0 z-0 overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-gradient-to-tr from-[#DECDF5] to-[#9089fc] opacity-30" />
        </div>
        <div className="mx-auto max-w-2xl text-center z-10">
          <div className="mb-8 flex justify-center animate-bounce">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20 font-cairo font-regular">
              تصفح طلبات التبرع بالدم{" "}
              <a className="font-semibold text-red-600">
                <span
                  onClick={() => handleMove()}
                  className="cursor-pointer absolute inset-0"
                  aria-hidden="true"
                />
                من هنا <span aria-hidden="true">&larr;</span>
              </a>
            </div>
          </div>
          <div className="mb-8">
            <BuyMeACoffeeButton/>
          </div>
          
          <div className="animate-fade-in">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl font-cairo font-regular">
              تبرّعك بالدم لا ينقذ حياة شخص واحد فقط، بل يمنح الأمل والحياة لعدة
              أرواح.
            </h1>
            <div className="mt-10 flex items-center justify-center gap-x-6 animate-pulse">
              <a
                onClick={() => handleClickOpen()}
                className="no-underline cursor-pointer font-cairo font-regular rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                إضافة طلب تبرع
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ position: "fixed", bottom: "20px", left: "20px" }}>
        <Fab
          onClick={() => handleClickOpen()}
          size="medium"
          color="error"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      </div> */}
      <Dialog2
        xs={{ fontFamily: "cairo" }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition2}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#C51605" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h7"
              component="div"
              style={{ fontFamily: "cairo", fontWeight: "700" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;إضافة طلب
            </Typography>
            {/* <Button
              autoFocus
              color="inherit"
              style={{ fontFamily: "cairo", fontWeight: "700" }}
            >
              نشر
            </Button> */}
          </Toolbar>
        </AppBar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Request />
        </div>
      </Dialog2>
    </div>
  );
};
export default Example;
